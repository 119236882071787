var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { ROUTES, PAGINAS } from '../../../routes';
import { BotaoAdicionar } from '../../../components/botoes';
import BarraPesquisar from '../../../components/grid_pesquisar';
import Grid from '../../../components/grid';
import { PaginationModelDefault } from '../../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../../components/grid_box';
import { getTiposImoveis } from './tipos_imoveis_service';
import { STATUS_API } from '../../../api';
export var TiposImoveisListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(''), TextoBusca = _b[0], setTextoBusca = _b[1];
    var _c = useState([]), TiposImoveis = _c[0], setTiposImoveis = _c[1];
    var _d = useState(0), TotalRegistros = _d[0], setTotalRegistros = _d[1];
    var _e = React.useState(PaginationModelDefault), PaginationModel = _e[0], setPaginationModel = _e[1];
    var getAcoesFuncionalidade = (useContext(AuthContext) || { getAcoesFuncionalidade: function () { return []; } }).getAcoesFuncionalidade;
    var _f = React.useState(getAcoesFuncionalidade(PAGINAS.TIPOS_IMOVEIS)), AcoesFuncionalidade = _f[0], setAcoesFuncionalidade = _f[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var Resposta, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTiposImoveis(TextoBuscaParm, PaginationModelParm)];
                    case 1:
                        Resposta = _a.sent();
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setTiposImoveis(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (Texto) {
        setTextoBusca(Texto);
        resetGrid(Texto, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, PaginationModelParm);
    };
    var resetGrid = function (Texto, PaginationModelParm) {
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(Texto, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'nome',
            headerName: 'Nome',
            width: 500,
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            //description: 'Situação',
            width: 150,
            //valueGetter: (value, row) => `${row.status == 'Aitvo' || ''} ${row.descricao || ''}`,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.TIPOS_IMOVEIS,
        VISUALIZAR: AcoesFuncionalidade['VISUALIZAR'],
        ALTERAR: AcoesFuncionalidade['ALTERAR'],
        EXCLUIR: AcoesFuncionalidade['EXCLUIR'],
    };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.TIPOS_IMOVEIS }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsx(BotaoAdicionar, { pagina: ROUTES.TIPOS_IMOVEIS, disabled: !AcoesFuncionalidade['CADASTRAR'] })] }), _jsx(BoxGrid, { children: _jsx(Grid, { columns: COLUNAS_FUNCIONALIDADE, rows: TiposImoveis, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange }) })] }) })));
};
export default TiposImoveisListar;
