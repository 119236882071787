var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Typography, MenuItem, Grid, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, Divider, } from '@mui/material';
import { MODOS, MENSAGENS, getDataFormatada, validarData, verificarDatas, parseDate, getCPFFormatado, } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoFechar, BotaoAuxiliar } from '../../../components/botoes';
import { InputDados } from '../../../components/input_dados';
import { ModeEditOutlined as ModeEditOutlinedIcon, DeleteOutline as DeleteOutlineIcon, } from '@mui/icons-material';
import GridComp from '../../../components/grid';
import { alterarAtendimento, alterarSubAtendimento, cadastrarSubAtendimento, excluirSubAtendimento, getAtendimento, getCombosCadastro, STATUS_API, } from './encaminhamentos_controle_social.service';
export var AtendimentosControleSocialEditar = function (props) {
    var _a, _b;
    var _c = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _c.DEV, addMensagem = _c.addMensagem, getAcoesFuncionalidade = _c.getAcoesFuncionalidade;
    var _d = React.useState(getAcoesFuncionalidade(PAGINAS.OFERTAS_VAGAS_TRABALHO_VAGAS)), AcoesFuncionalidade = _d[0], setAcoesFuncionalidade = _d[1];
    var navigate = useNavigate();
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(true), LoadingInicio = _f[0], setLoadingInicio = _f[1];
    var _g = useParams(), id = _g.id, modo = _g.modo;
    var _h = useState(), Atendimento = _h[0], setAtendimento = _h[1];
    var _j = useState([]), SubAtendimentos = _j[0], setSubAtendimentos = _j[1];
    var _k = useState(), SituacaoAtendimentoSelecionada = _k[0], setSituacaoAtendimentoSelecionada = _k[1];
    var _l = useState([]), TiposAntendimentosDisponiveis = _l[0], setTiposAntendimentosDisponiveis = _l[1];
    var _m = useState([]), ModalidadesAntendimentosDisponiveis = _m[0], setModalidadesAntendimentosDisponiveis = _m[1];
    var _o = useState([]), SituacoesDisponiveis = _o[0], setSituacoesDisponiveis = _o[1];
    var _p = useState(''), DataAtendimento = _p[0], setDataAtendimento = _p[1];
    var _q = useState(-1), ModalidadeSelecionada = _q[0], setModalidadeSelecionada = _q[1];
    var _r = useState(-1), TipoAtendimentoSelecionado = _r[0], setTipoAtendimentoSelecionado = _r[1];
    var _s = useState('EM ATENDIMENTO'), SituacaoSelecionada = _s[0], setSituacaoSelecionada = _s[1];
    var _t = useState(), EditarID = _t[0], setEditarID = _t[1];
    var _u = useState(''), EditarDataAtendimento = _u[0], setEditarDataAtendimento = _u[1];
    var _v = useState(-1), EditarModalidadeSelecionada = _v[0], setEditarModalidadeSelecionada = _v[1];
    var _w = useState(-1), EditarTipoAtendimentoSelecionado = _w[0], setEditarTipoAtendimentoSelecionado = _w[1];
    var _x = useState(''), EditarSituacaoSelecionada = _x[0], setEditarSituacaoSelecionada = _x[1];
    var _y = useState(false), PopupAberto = _y[0], setPopupAberto = _y[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var AtendimentoAux, sub_atendimentos_controle_social, situacao, CombosEditar, tipos_atendimentos, modalidade_atendimentos, situacoes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id) return [3 /*break*/, 3];
                    return [4 /*yield*/, getAtendimento(id)];
                case 1:
                    AtendimentoAux = _a.sent();
                    if (AtendimentoAux.Status == STATUS_API.ERRO) {
                        alert(AtendimentoAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        sub_atendimentos_controle_social = AtendimentoAux.sub_atendimentos_controle_social, situacao = AtendimentoAux.situacao;
                        setSituacaoAtendimentoSelecionada(situacao);
                        setSubAtendimentos(sub_atendimentos_controle_social);
                        setAtendimento(AtendimentoAux);
                    }
                    return [4 /*yield*/, getCombosCadastro()];
                case 2:
                    CombosEditar = _a.sent();
                    if (CombosEditar.Status == STATUS_API.ERRO) {
                        alert(CombosEditar.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        tipos_atendimentos = CombosEditar.tipos_atendimentos, modalidade_atendimentos = CombosEditar.modalidade_atendimentos, situacoes = CombosEditar.situacoes;
                        setTiposAntendimentosDisponiveis(tipos_atendimentos);
                        setModalidadesAntendimentosDisponiveis(modalidade_atendimentos);
                        setSituacoesDisponiveis(situacoes);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    alert('Atendimento não informado');
                    _a.label = 4;
                case 4:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!DataAtendimento) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!validarData(DataAtendimento)) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
            return false;
        }
        if (!verificarDatas(DataAtendimento, getDataFormatada(new Date().toDateString()), true)) {
            alert("Data ".concat(MENSAGENS.DATA_MAIOR_DATA_ATUAL));
            return false;
        }
        if (!ModalidadeSelecionada || ModalidadeSelecionada == -1) {
            alert("Modalidade ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!TipoAtendimentoSelecionado || TipoAtendimentoSelecionado == -1) {
            alert("Tipo ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var validarDadosEditar = function () {
        if (!EditarDataAtendimento) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!validarData(EditarDataAtendimento)) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
            return false;
        }
        if (!verificarDatas(EditarDataAtendimento, getDataFormatada(new Date().toDateString()), true)) {
            alert("Data ".concat(MENSAGENS.DATA_MAIOR_DATA_ATUAL));
            return false;
        }
        if (!EditarModalidadeSelecionada || EditarModalidadeSelecionada == -1) {
            alert("Modalidade ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!EditarTipoAtendimentoSelecionado || EditarTipoAtendimentoSelecionado == -1) {
            alert("Tipo ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var adicionarSubAtendimento = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        data: parseDate(DataAtendimento),
                        modalidade_atendimento_id: ModalidadeSelecionada,
                        tipo_atendimento_id: TipoAtendimentoSelecionado,
                        situacao: SituacaoSelecionada,
                    };
                    return [4 /*yield*/, cadastrarSubAtendimento(Dados, id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _b.sent();
                    //console.log('Resp', JSON.stringify(Resp));
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        setSubAtendimentos((_a = Resp.dados.sub_atendimentos_controle_social) !== null && _a !== void 0 ? _a : []);
                        limparSelecao();
                    }
                    else {
                        alert(Resp.Mensagem);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var alterarSubatendimento = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDadosEditar())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        data: parseDate(EditarDataAtendimento),
                        modalidade_atendimento_id: EditarModalidadeSelecionada,
                        tipo_atendimento_id: EditarTipoAtendimentoSelecionado,
                        situacao: EditarSituacaoSelecionada,
                    };
                    return [4 /*yield*/, alterarSubAtendimento(Dados, id !== null && id !== void 0 ? id : '', EditarID ? EditarID.toString() : '')];
                case 1:
                    Resp = _b.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        setSubAtendimentos((_a = Resp.dados.sub_atendimentos_controle_social) !== null && _a !== void 0 ? _a : []);
                        setPopupAberto(false);
                        limparPopup();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (subatendimento_id) { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO)) return [3 /*break*/, 2];
                    setLoading(true);
                    return [4 /*yield*/, excluirSubAtendimento(id !== null && id !== void 0 ? id : '', subatendimento_id)];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        setSubAtendimentos(function (SubAnterior) { return SubAnterior.filter(function (Item) { return Item.id != +subatendimento_id; }); });
                    }
                    else {
                        setLoading(false);
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var limparSelecao = function () {
        setDataAtendimento('');
        setModalidadeSelecionada(-1);
        setTipoAtendimentoSelecionado(-1);
        setSituacaoSelecionada('EM ATENDIMENTO');
    };
    var limparPopup = function () {
        setEditarID(undefined);
        setEditarDataAtendimento('');
        setEditarModalidadeSelecionada(-1);
        setEditarTipoAtendimentoSelecionado(-1);
        setEditarSituacaoSelecionada('');
    };
    var COLUNAS = [
        {
            field: 'id',
            headerName: 'Código',
            width: 100,
        },
        {
            field: 'data',
            headerName: 'Data',
            width: 120,
            valueGetter: function (value, row) { return getDataFormatada(row.data); },
        },
        {
            field: 'modalidade_atendimento',
            headerName: 'Modalidade',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.modalidade_atendimento) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'tipo_atendimento',
            headerName: 'Tipo de Encaminhamento',
            width: 250,
            valueGetter: function (value, row) { var _a; return (_a = row.tipo_atendimento) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 150,
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 80,
            renderCell: function (params) {
                var _a, _b;
                return (_jsxs(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: [_jsx(Box, __assign({ bgcolor: 'primary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                                    var _a;
                                    abrirPopup((_a = params.row) === null || _a === void 0 ? void 0 : _a.id);
                                }, disabled: DESABILITAR_CAMPOS ||
                                    !AcoesFuncionalidade['ALTERAR'] ||
                                    ((_a = params.row) === null || _a === void 0 ? void 0 : _a.tipo_status_vaga) == 'FINALIZADA' ||
                                    ((_b = params.row) === null || _b === void 0 ? void 0 : _b.tipo_status_vaga) == 'ENCERRADA' }, { children: _jsx(ModeEditOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })), _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () { var _a; return handleDelete((_a = params.row) === null || _a === void 0 ? void 0 : _a.id); }, disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['EXCLUIR'] }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) }))] })));
            },
        },
    ];
    var abrirPopup = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
        var SubAtendimentoAux, id_1, data, modalidade_atendimento_id, tipo_atendimento_id, situacao;
        return __generator(this, function (_a) {
            setLoading(true);
            SubAtendimentoAux = SubAtendimentos.find(function (Item) { return Item.id == +ID; });
            if (SubAtendimentoAux) {
                id_1 = SubAtendimentoAux.id, data = SubAtendimentoAux.data, modalidade_atendimento_id = SubAtendimentoAux.modalidade_atendimento_id, tipo_atendimento_id = SubAtendimentoAux.tipo_atendimento_id, situacao = SubAtendimentoAux.situacao;
                setEditarID(id_1);
                setEditarDataAtendimento(getDataFormatada(data));
                setEditarModalidadeSelecionada(modalidade_atendimento_id);
                setEditarTipoAtendimentoSelecionado(tipo_atendimento_id);
                setEditarSituacaoSelecionada(situacao);
            }
            setLoading(false);
            setPopupAberto(true);
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        if (confirm('Tem certeza que deseja sair?')) {
            setPopupAberto(false);
        }
    };
    var changeSituacao = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var SituacaoAux, Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm('Tem certeza que deseja alterar a situação?'))
                        return [2 /*return*/];
                    setLoading(true);
                    SituacaoAux = event.target.value;
                    setSituacaoAtendimentoSelecionada(SituacaoAux);
                    return [4 /*yield*/, alterarAtendimento({ situacao: event.target.value }, id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        alert(MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL, link_pagina_acima: ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL }, { children: _jsx(BoxPrincipal, __assign({ sx: { width: isMobile ? '100%' : '100%', mt: -3 } }, { children: !LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Itens do Atendimento" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 8 }, { children: _jsx(InputDados, { value: "".concat((_a = Atendimento === null || Atendimento === void 0 ? void 0 : Atendimento.reeducando) === null || _a === void 0 ? void 0 : _a.nome, " - CPF: ").concat(getCPFFormatado((_b = Atendimento === null || Atendimento === void 0 ? void 0 : Atendimento.reeducando) === null || _b === void 0 ? void 0 : _b.cpf)), disabled: true }) })), _jsx(Grid, __assign({ item: true, xs: 4, sm: 4 }, { children: _jsx(InputDados, __assign({ select: true, id: 'situacao', label: 'Situa\u00E7\u00E3o Geral', placeholder: 'Situa\u00E7\u00E3o Geral', value: SituacaoAtendimentoSelecionada, onChange: changeSituacao, disabled: DESABILITAR_CAMPOS }, { children: SituacoesDisponiveis &&
                                                SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); }) })) })), _jsx(Grid, { item: true, xs: 4 })] })), _jsx(Divider, { style: { marginTop: 20, marginBottom: 20, color: 'grey' } }), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, { id: 'data', label: 'Data', mask: '99/99/9999', value: DataAtendimento, onChange: function (event) { return setDataAtendimento(event.target.value); }, disabled: DESABILITAR_CAMPOS, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'modalidade-atendimento', label: 'Modalidade de Atendimento', placeholder: 'Modalidade de Atendimento', value: ModalidadeSelecionada, onChange: function (event) {
                                                setModalidadeSelecionada(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), ModalidadesAntendimentosDisponiveis &&
                                                    ModalidadesAntendimentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-atendimento', label: 'Tipo de Encaminhamento', placeholder: 'Tipo de Encaminhamento', value: TipoAtendimentoSelecionado, onChange: function (event) {
                                                setTipoAtendimentoSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposAntendimentosDisponiveis &&
                                                    TiposAntendimentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 4 }, { children: _jsx(InputDados, __assign({ select: true, id: 'situacao_item', label: 'Situa\u00E7\u00E3o Item', placeholder: 'Situa\u00E7\u00E3o Item', value: SituacaoSelecionada, onChange: function (event) {
                                                setSituacaoSelecionada(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: SituacoesDisponiveis &&
                                                SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); }) })) })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: [_jsx(BotaoAuxiliar, __assign({ onClick: limparSelecao, backgroundColor: 'error.main', disabled: DESABILITAR_CAMPOS }, { children: "Limpar" })), _jsx(BotaoAuxiliar, __assign({ sx: { ml: 1 }, onClick: adicionarSubAtendimento, backgroundColor: 'principal.main', disabled: DESABILITAR_CAMPOS || !AcoesFuncionalidade['CADASTRAR'] }, { children: "Adicionar" }))] }))] })), _jsx(Box, __assign({ mt: 8 }, { children: _jsx(GridComp, { rows: SubAtendimentos, columns: COLUNAS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })), _jsx(BoxBotoes, __assign({ sx: { marginTop: 10 } }, { children: _jsx(BotaoFechar, { modo: modo, pagina: ROUTES.ENCAMINHAMENTOS_ESCRITORIO_SOCIAL }) }))] }), _jsxs(Dialog, __assign({ open: PopupAberto, onClose: handleClose, maxWidth: 'sm', fullWidth: true }, { children: [_jsx(DialogTitle, { children: "Editar Item" }), _jsxs(DialogContent, __assign({ style: { margin: 5, padding: 10 } }, { children: [_jsx(Box, __assign({ mb: 2, mt: 2 }, { children: _jsx(InputDados, { id: 'id', label: 'ID', value: EditarID, disabled: true }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsx(InputDados, { id: 'data', label: 'Data', mask: '99/99/9999', value: EditarDataAtendimento, onChange: function (event) { return setEditarDataAtendimento(event.target.value); }, disabled: DESABILITAR_CAMPOS, required: true }) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'modalidade-atendimento', label: 'Modalidade de Atendimento', placeholder: 'Modalidade de Atendimento', value: EditarModalidadeSelecionada, onChange: function (event) {
                                                setEditarModalidadeSelecionada(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), ModalidadesAntendimentosDisponiveis &&
                                                    ModalidadesAntendimentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo-encaminhamento', label: 'Tipo de Encaminhamento', placeholder: 'Tipo de Encaminhamento', value: EditarTipoAtendimentoSelecionado, onChange: function (event) {
                                                setEditarTipoAtendimentoSelecionado(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposAntendimentosDisponiveis &&
                                                    TiposAntendimentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ mb: 2 }, { children: _jsx(InputDados, __assign({ select: true, id: 'situacao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: EditarSituacaoSelecionada, onChange: function (event) {
                                                setEditarSituacaoSelecionada(event.target.value);
                                            }, disabled: DESABILITAR_CAMPOS }, { children: SituacoesDisponiveis &&
                                                SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); }) })) }))] })), _jsxs(DialogActions, __assign({ style: { padding: 20 } }, { children: [_jsx(Button, __assign({ onClick: handleClose }, { children: "Cancelar" })), _jsx(Button, __assign({ onClick: alterarSubatendimento, variant: 'contained', color: 'primary' }, { children: "Salvar" }))] }))] })), loading && _jsx(Loading, {})] })) })) })));
};
export default AtendimentosControleSocialEditar;
