var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Button, IconButton, MenuItem, Paper, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme, InputBase, } from '@mui/material';
import { MODOS, MENSAGENS, getDataFormatada, validarData, verificarDatas, parseDate, getCPFFormatado, } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../../components/botoes';
import { InputDados } from '../../../components/input_dados';
import { alterarAtendimento, getCombosCadastro, getAtendimento, STATUS_API, cadastrarAtendimento, excluirAtendimento, } from './atendimentos_identificacao_civil.service';
import { ReeducandosBusca } from '../../reecrutamentos/reeducandos';
import { Search } from '@mui/icons-material';
export var AtendimentosIdentificacaoCivilEditar = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.ATENDIMENTOS_IDENTIFICACAO_CIVIL)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState(), Atendimento = _f[0], setAtendimento = _f[1];
    var _g = useState([]), OrgaosDisponiveis = _g[0], setOrgaosDisponiveis = _g[1];
    var _h = useState([]), RegimesReclusaoDisponiveis = _h[0], setRegimesReclusaoDisponiveis = _h[1];
    var _j = useState([]), TiposAntendimentosDisponiveis = _j[0], setTiposAntendimentosDisponiveis = _j[1];
    var _k = useState([]), SituacoesDisponiveis = _k[0], setSituacoesDisponiveis = _k[1];
    var _l = useState(), Reeducando = _l[0], setReeducando = _l[1];
    var _m = useState(-1), ReeducandoSelecionado = _m[0], setReeducandoSelecionado = _m[1];
    var _o = useState(-1), TipoAtendimentoSelecionado = _o[0], setTipoAtendimentoSelecionado = _o[1];
    var _p = useState(-1), RegimeReclusaoSelecionado = _p[0], setRegimeReclusaoSelecionado = _p[1];
    var _q = useState(-1), OrgaoSelecionado = _q[0], setOrgaoSelecionado = _q[1];
    var _r = useState('EM ATENDIMENTO'), SituacaoSelecionada = _r[0], setSituacaoSelecionada = _r[1];
    var _s = useState(''), Parentesco = _s[0], setParentesco = _s[1];
    var _t = useState(''), Observacao = _t[0], setObservacao = _t[1];
    var _u = useState(''), Data = _u[0], setData = _u[1];
    var _v = useState(false), PopupAbertoReeducando = _v[0], setPopupAbertoReeducando = _v[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var AtendimentoAux, reeducando_id, reeducando, tipo_atendimento_id, orgao_id, tipo_regime_id, data, parentesco, observacao, situacao, CombosEditar, tipos_atendimentos, orgaos, tipos_regimes_pena, situacoes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, getAtendimento(id)];
                case 1:
                    AtendimentoAux = _a.sent();
                    if (AtendimentoAux.Status == STATUS_API.ERRO) {
                        alert(AtendimentoAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        reeducando_id = AtendimentoAux.reeducando_id, reeducando = AtendimentoAux.reeducando, tipo_atendimento_id = AtendimentoAux.tipo_atendimento_id, orgao_id = AtendimentoAux.orgao_id, tipo_regime_id = AtendimentoAux.tipo_regime_id, data = AtendimentoAux.data, parentesco = AtendimentoAux.parentesco, observacao = AtendimentoAux.observacao, situacao = AtendimentoAux.situacao;
                        setReeducandoSelecionado(reeducando_id);
                        setReeducando(reeducando);
                        setTipoAtendimentoSelecionado(tipo_atendimento_id);
                        setOrgaoSelecionado(orgao_id);
                        setRegimeReclusaoSelecionado(tipo_regime_id);
                        setData(getDataFormatada(data));
                        setParentesco(parentesco);
                        setObservacao(observacao);
                        setSituacaoSelecionada(situacao);
                        setAtendimento(AtendimentoAux);
                    }
                    _a.label = 2;
                case 2: return [4 /*yield*/, getCombosCadastro()];
                case 3:
                    CombosEditar = _a.sent();
                    if (CombosEditar.Status == STATUS_API.ERRO) {
                        alert(CombosEditar.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        tipos_atendimentos = CombosEditar.tipos_atendimentos, orgaos = CombosEditar.orgaos, tipos_regimes_pena = CombosEditar.tipos_regimes_pena, situacoes = CombosEditar.situacoes;
                        setTiposAntendimentosDisponiveis(tipos_atendimentos);
                        setOrgaosDisponiveis(orgaos);
                        setRegimesReclusaoDisponiveis(tipos_regimes_pena);
                        setSituacoesDisponiveis(situacoes);
                    }
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (ReeducandoSelecionado == -1) {
            alert("Reeducando ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!(OrgaoSelecionado > 0)) {
            alert("\u00D3rg\u00E3o ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!(TipoAtendimentoSelecionado > 0)) {
            alert("Tipo de Atendimento ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!(RegimeReclusaoSelecionado > 0)) {
            alert("Regime de Reclus\u00E3o ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!Data) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!validarData(Data)) {
            alert("Data ".concat(MENSAGENS.PREENCHIMENTO_INVALIDO));
            return false;
        }
        if (!verificarDatas(Data, getDataFormatada(new Date().toDateString()), true)) {
            alert("Data ".concat(MENSAGENS.DATA_MAIOR_DATA_ATUAL));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        reeducando_id: ReeducandoSelecionado,
                        tipo_atendimento_id: TipoAtendimentoSelecionado,
                        orgao_id: OrgaoSelecionado,
                        tipo_regime_id: RegimeReclusaoSelecionado,
                        data: parseDate(Data),
                        parentesco: Parentesco,
                        situacao: SituacaoSelecionada,
                        observacao: Observacao,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarAtendimento(Dados)];
                case 2:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarAtendimento(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate(ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirAtendimento(id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL, "/").concat(id, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        setPopupAbertoReeducando(false);
        carregarDados();
    };
    var selecionarReeducando = function (reeducando) {
        setReeducando(reeducando);
        setReeducandoSelecionado(reeducando.id);
        setPopupAbertoReeducando(false);
    };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.ATENDIMENTOS_IDENTIFICACAO_CIVIL, link_pagina_acima: ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL }, { children: _jsxs(BoxPrincipal, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Paper, __assign({ component: 'form', sx: { p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' } }, { children: [_jsx(InputBase, { sx: { ml: 1, flex: 1 }, placeholder: 'Reeducando', inputProps: { 'aria-label': 'search google maps' }, autoFocus: true, value: Reeducando ? "".concat(Reeducando === null || Reeducando === void 0 ? void 0 : Reeducando.nome, " - CPF: ").concat(getCPFFormatado(Reeducando === null || Reeducando === void 0 ? void 0 : Reeducando.cpf)) : '', disabled: true }), _jsx(IconButton, __assign({ type: 'button', sx: { p: '10px' }, "aria-label": 'search', onClick: function () {
                                        setPopupAbertoReeducando(true);
                                    } }, { children: _jsx(Search, {}) }))] })), _jsxs(InputDados, __assign({ select: true, label: 'Local de Atendimento', value: OrgaoSelecionado, onChange: function (event) { return setOrgaoSelecionado(event.target.value); }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: "Selecione" })), OrgaosDisponiveis &&
                                    OrgaosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, label: 'Tipo de Atendimento', value: TipoAtendimentoSelecionado, onChange: function (event) { return setTipoAtendimentoSelecionado(event.target.value); }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: "Selecione" })), TiposAntendimentosDisponiveis &&
                                    TiposAntendimentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, label: 'Regime de Reclus\u00E3o', value: RegimeReclusaoSelecionado, onChange: function (event) { return setRegimeReclusaoSelecionado(event.target.value); }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: "Selecione" })), RegimesReclusaoDisponiveis &&
                                    RegimesReclusaoDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsx(InputDados, { label: 'Data', mask: '99/99/9999', value: Data, onChange: function (event) { return setData(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Parentesco', value: Parentesco, onChange: function (event) { return setParentesco(event.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, __assign({ select: true, label: 'Situa\u00E7\u00E3o', value: SituacaoSelecionada, onChange: function (event) { return setSituacaoSelecionada(event.target.value); }, required: true, disabled: DESABILITAR_CAMPOS }, { children: SituacoesDisponiveis &&
                                SituacoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); }) })), _jsx(InputDados, { label: 'Observa\u00E7\u00E3o', value: Observacao, onChange: function (event) { return setObservacao(event.target.value); }, multiline: true, rows: 4, disabled: DESABILITAR_CAMPOS }), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == modo && _jsx(BotaoExcluir, { onClick: excluirRegistro }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade['ALTERAR'] }), _jsx(BotaoFechar, { modo: modo })] }), _jsxs(Dialog, __assign({ open: PopupAbertoReeducando, onClose: handleClose, maxWidth: 'xl' }, { children: [_jsx(DialogContent, __assign({ style: { margin: 0 } }, { children: _jsx(ReeducandosBusca, { selecionarReeducando: selecionarReeducando }) })), _jsx(DialogActions, __assign({ style: { padding: 20 } }, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Voltar" })) }))] }))] })), loading && _jsx(Loading, {})] }) })));
};
export default AtendimentosIdentificacaoCivilEditar;
