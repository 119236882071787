var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, MenuItem, Divider, Tooltip } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { MODOS, MENSAGENS, getNomeFormatado, getfloatToTimeString } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import ContainerModal from '../../../components/container_modal';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getJornadaTrabalho, cadastrarJornadaTrabalho, alterarJornadaTrabalho, excluirJornadaTrabalho } from './jornadas_trabalho.service';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../../components/botoes';
import InputDados from '../../../components/input_dados';
var diasDaSemana = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
export var JornadasTrabalhoAlterar = function (props) {
    var _a;
    var _b = useContext(AuthContext) || { addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, addMensagem = _b.addMensagem, getAcoesFuncionalidade = _b.getAcoesFuncionalidade;
    var _c = React.useState(getAcoesFuncionalidade(PAGINAS.JORNADAS_TRABALHO)), AcoesFuncionalidade = _c[0], setAcoesFuncionalidade = _c[1];
    var navigate = useNavigate();
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState(true), LoadingInicio = _e[0], setLoadingInicio = _e[1];
    var _f = useParams(), id = _f.id, modo = _f.modo;
    var ID = (_a = props.id) !== null && _a !== void 0 ? _a : id;
    var MODO = modo !== null && modo !== void 0 ? modo : props.modo;
    var _g = useState({}), JornadaTrabalho = _g[0], setJornadaTrabalho = _g[1];
    var _h = useState(''), Nome = _h[0], setNome = _h[1];
    var _j = useState(''), Tolerancia = _j[0], setTolerancia = _j[1];
    var _k = useState(0), TotalHoras = _k[0], setTotalHoras = _k[1];
    var _l = useState('Ativo'), Situacao = _l[0], setSituacao = _l[1];
    var _m = useState(diasDaSemana.map(function (dia) { return ({ dia_semana: dia, entrada1: '', saida1: '', entrada2: '', saida2: '', entrada3: '', saida3: '', saldo: '00:00' }); })), horarios = _m[0], setHorarios = _m[1];
    var DESABILITAR_CAMPOS = MODO == MODOS.VISUALIZAR || MODO == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var JornadaTrabalhoAux, nome, tolerancia, horarios_1, total_horas, situacao;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!ID) return [3 /*break*/, 2];
                    return [4 /*yield*/, getJornadaTrabalho(ID)];
                case 1:
                    JornadaTrabalhoAux = _a.sent();
                    setJornadaTrabalho(JornadaTrabalhoAux);
                    //console.log('JornadaTrabalho', JSON.stringify(JornadaTrabalhoAux));
                    if (JornadaTrabalhoAux) {
                        nome = JornadaTrabalhoAux.nome, tolerancia = JornadaTrabalhoAux.tolerancia, horarios_1 = JornadaTrabalhoAux.horarios, total_horas = JornadaTrabalhoAux.total_horas, situacao = JornadaTrabalhoAux.situacao;
                        setNome(nome);
                        setTolerancia(tolerancia);
                        setHorarios(horarios_1);
                        setTotalHoras(total_horas);
                        setSituacao(situacao);
                    }
                    _a.label = 2;
                case 2:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!Nome || Nome.trim() == '') {
            alert("Nome ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        nome: getNomeFormatado(Nome),
                        tolerancia: Tolerancia ? parseInt(Tolerancia) : null,
                        horarios: horarios,
                        total_horas: TotalHoras,
                        situacao: Situacao,
                    };
                    _a = MODO;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarJornadaTrabalho(Dados)];
                case 2:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarJornadaTrabalho(Dados, ID !== null && ID !== void 0 ? ID : '')];
                case 4:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MODO == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate(ROUTES.JORNADAS_TRABALHO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirJornadaTrabalho(ID !== null && ID !== void 0 ? ID : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.JORNADAS_TRABALHO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.JORNADAS_TRABALHO, "/").concat(ID, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    var calcularSaldoHoras = function (entrada1, saida1, entrada2, saida2, entrada3, saida3) {
        var calcularHoras = function (inicio, fim) {
            if (!inicio || !fim)
                return 0;
            var _a = inicio.split(':').map(Number), hInicio = _a[0], mInicio = _a[1];
            var _b = fim.split(':').map(Number), hFim = _b[0], mFim = _b[1];
            return hFim * 60 + mFim - (hInicio * 60 + mInicio);
        };
        var saldo1 = calcularHoras(entrada1, saida1);
        var saldo2 = calcularHoras(entrada2, saida2);
        var saldo3 = calcularHoras(entrada3, saida3);
        var totalMinutos = saldo1 + saldo2 + saldo3;
        var horas = Math.floor(totalMinutos / 60);
        var minutos = totalMinutos % 60;
        return "".concat(String(horas).padStart(2, '0'), ":").concat(String(minutos).padStart(2, '0'));
    };
    var handleChange = function (index, field, value) {
        var novosHorarios = __spreadArray([], horarios, true);
        novosHorarios[index][field] = value;
        var _a = novosHorarios[index], entrada1 = _a.entrada1, saida1 = _a.saida1, entrada2 = _a.entrada2, saida2 = _a.saida2, entrada3 = _a.entrada3, saida3 = _a.saida3;
        novosHorarios[index].saldo = calcularSaldoHoras(entrada1, saida1, entrada2, saida2, entrada3, saida3);
        setHorarios(novosHorarios);
        calculateTotalHours(novosHorarios);
    };
    var calculateHoursDifference = function (start, end) {
        if (!start || !end)
            return 0;
        var _a = start.split(':').map(Number), startHours = _a[0], startMinutes = _a[1];
        var _b = end.split(':').map(Number), endHours = _b[0], endMinutes = _b[1];
        var startDate = new Date(0, 0, 0, startHours, startMinutes, 0);
        var endDate = new Date(0, 0, 0, endHours, endMinutes, 0);
        var diff = endDate.getTime() - startDate.getTime();
        return diff / (1000 * 60 * 60); // Convert milliseconds to hours
    };
    var isValidTime = function (time) {
        var regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(time);
    };
    var calculateTotalHours = function (horarios) {
        var total = 0;
        horarios.forEach(function (horario) {
            total += calculateHoursDifference(horario.entrada1, horario.saida1);
            total += calculateHoursDifference(horario.entrada2, horario.saida2);
            total += calculateHoursDifference(horario.entrada3, horario.saida3);
        });
        setTotalHoras(total);
    };
    var duplicarLinha = function (dia_semana) {
        //quero que este duplicar linha apenas duplique os dados do dia atual para o proximo dia
        var index = diasDaSemana.indexOf(dia_semana);
        var proximoDia = diasDaSemana[index + 1];
        var horarioAtual = horarios[index];
        var horarioProximo = horarios[index + 1];
        if (!horarioProximo)
            return;
        horarioProximo.entrada1 = horarioAtual.entrada1;
        horarioProximo.saida1 = horarioAtual.saida1;
        horarioProximo.entrada2 = horarioAtual.entrada2;
        horarioProximo.saida2 = horarioAtual.saida2;
        horarioProximo.entrada3 = horarioAtual.entrada3;
        horarioProximo.saida3 = horarioAtual.saida3;
        horarioProximo.saldo = horarioAtual.saldo;
        setHorarios(__spreadArray([], horarios, true));
        calculateTotalHours(horarios);
    };
    var Container = props.popup ? ContainerModal : ContainerPainel;
    return (_jsx(Container, __assign({ modo: MODO, pagina_acima: PAGINAS.JORNADAS_TRABALHO, link_pagina_acima: ROUTES.JORNADAS_TRABALHO }, { children: _jsxs(BoxPrincipal, __assign({ sx: { width: '70%' } }, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsx(InputDados, { id: 'id', variant: 'outlined', disabled: true, label: 'C\u00F3digo', value: ID, size: 'small' }), _jsx(InputDados, { id: 'nome', label: 'Nome', value: Nome, onChange: function (event) { return setNome(event.target.value); }, variant: 'outlined', size: 'small', fullWidth: true, required: true, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { id: 'tolerancia', label: 'Toler\u00E2ncia(minutos)', mask: '99', value: Tolerancia, onChange: function (event) { return setTolerancia(event.target.value); }, variant: 'outlined', size: 'small', fullWidth: true, disabled: DESABILITAR_CAMPOS }), _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
                            , label: 'Situa\u00E7\u00E3o' // obrigatorio
                            , placeholder: 'Situa\u00E7\u00E3o', value: Situacao, onChange: function (event) {
                                setSituacao(event.target.value);
                            }, size: 'small', fullWidth: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })), _jsx(Divider, __assign({ style: { marginTop: 15, marginBottom: 15, color: 'grey' } }, { children: "HOR\u00C1RIOS" })), _jsx(Box, { children: horarios &&
                                horarios.map(function (horario, index) { return (_jsxs(Box, __assign({ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }, { children: [_jsx(Box, __assign({ minWidth: 150 }, { children: _jsx(InputDados, { label: 'Dia da Semana', value: horario.dia_semana, disabled: true }) })), _jsx(InputDados, { label: 'Entrada 1', mask: '99:99', value: horario.entrada1, onChange: function (e) { return handleChange(index, 'entrada1', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Sa\u00EDda 1', mask: '99:99', value: horario.saida1, onChange: function (e) { return handleChange(index, 'saida1', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Entrada 2', mask: '99:99', value: horario.entrada2, onChange: function (e) { return handleChange(index, 'entrada2', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Sa\u00EDda 2', mask: '99:99', value: horario.saida2, onChange: function (e) { return handleChange(index, 'saida2', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Entrada 3', mask: '99:99', value: horario.entrada3, onChange: function (e) { return handleChange(index, 'entrada3', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Sa\u00EDda 3', mask: '99:99', value: horario.saida3, onChange: function (e) { return handleChange(index, 'saida3', e.target.value); }, disabled: DESABILITAR_CAMPOS }), _jsx(InputDados, { label: 'Saldo', value: horario.saldo, disabled: true }), !DESABILITAR_CAMPOS && (_jsx(Tooltip, __assign({ title: 'Duplicar Linha', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                                                        duplicarLinha(horario.dia_semana);
                                                    } }, { children: _jsx(NoteAddIcon, { fontSize: 'large', style: {} }) })) }) })))] }), index)); }) }), _jsx(InputDados, { label: 'Total de Horas', value: getfloatToTimeString(TotalHoras), disabled: true }), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == MODO || MODOS.CADASTRAR == MODO) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == MODO && _jsx(BotaoExcluir, { onClick: excluirRegistro }), !props.popup && MODOS.VISUALIZAR == MODO && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade['ALTERAR'] }), !props.popup && _jsx(BotaoFechar, { modo: MODO })] })] })), loading && _jsx(Loading, {})] })) })));
};
export default JornadasTrabalhoAlterar;
